import React, { useState, lazy, Suspense } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {
  setCurrency,
  setPrevCurrency,
} from "../../Redux/feature/currencySlice";
import { updateTrendingProperties } from "../../Redux/feature/homeSlice";
import Buttons from "../Buttons";

const LoginIcon = lazy(() => import("@mui/icons-material/Login"));
const MapsHomeWorkIcon = lazy(() => import("@mui/icons-material/MapsHomeWork"));
const Logout = lazy(() => import("@mui/icons-material/Logout"));
const FavoriteBorderIcon = lazy(() =>
  import("@mui/icons-material/FavoriteBorder")
);
const ArticleOutlinedIcon = lazy(() =>
  import("@mui/icons-material/ArticleOutlined")
);
const PersonOutlinedIcon = lazy(() =>
  import("@mui/icons-material/PersonOutlined")
);

const Navbar = () => {
  const dispatch = useDispatch();
  const { homeData } = useSelector((state) => state?.isHome);
  const xs = useMediaQuery("(max-Width: 600px)");
  const sm = useMediaQuery("(min-width: 601px) and (max-width: 900px)");
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state?.isUser);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("name");
  const role = localStorage.getItem("role");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [getCurrency, setGetCurrency] = useState("EUR");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCurrencyChange = async (event) => {
    dispatch(setPrevCurrency(getCurrency));
    getConvertedValue(event.target.value, getCurrency);
    setGetCurrency(event.target.value);
    const sendCurrency = event.target.value;
    dispatch(setCurrency(sendCurrency));
  };

  const getConvertedValue = async (curr, prev) => {
    let finalConversionRate = 1;
    const conversionRate = localStorage.getItem(`${prev}_${curr}`);
    if (conversionRate) {
      finalConversionRate = conversionRate;
    } else {
      const convertedValue = await getConversion(curr, prev);
      if (convertedValue) {
        localStorage.setItem(`${prev}_${curr}`, convertedValue);
        finalConversionRate = convertedValue;
      }
    }
    const trendingDetails = homeData?.trending_properties?.map((detail) => {
      return {
        ...detail,
        price: detail.price * finalConversionRate,
      };
    });

    dispatch(updateTrendingProperties(trendingDetails));
  };

  const getConversion = async (currentCurrency, prevCurrency) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}api/get-currency?to_currency=${currentCurrency}&amount=1&from_currency=${prevCurrency}`
      );
      const data = await response.json();
      return data?.data;
    } catch (error) {
      return null;
    }
  };

  const handleListYourProperty = () => {
    if (userId && token) {
      navigate("/listYourProperty");
    } else {
      navigate("/wantToListWithUs");
    }
  };

  const handleHelpSection = () => {
    navigate("/help");
  };

  return (
    <>
      <Box
        sx={{
          height: { xs: "4rem", sm: "5rem" },
          width: "100%",
          backgroundColor: "white.main",
        }}
      >
        <Container
          maxWidth="lg"
          disableGutters={true}
          sx={{
            padding: ".5rem",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box
              onClick={() => navigate("/")}
              sx={{
                height: { xs: "2.7rem", sm: "3.5rem" },
                width: { xs: "2.7rem", sm: "3.5rem" },
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <img
                // src={require("../../Assets/Company/logo.png")}
                src={`${process.env.REACT_APP_API}media/Images/companyLogo.jpg`}
                alt="No Preview"
                style={{ height: "100%", width: "100%", objectFit: "fill" }}
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("../../Assets/Images/no-preview.jpeg");
                }}
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Select
              value={getCurrency}
              sx={{
                paddingTop: "0px",
                paddingBottom: "0px",
                height: "2.3rem",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000000",
                  borderRadius: ".5rem",
                },
              }}
              aria-label="Select an option"
              onChange={handleCurrencyChange}
            >
              <MenuItem value="EUR">EUR</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="INR">INR</MenuItem>
            </Select>

            <Buttons onClick={handleListYourProperty} bgColor={"#e87b25"}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Suspense>
                  <MapsHomeWorkIcon />
                </Suspense>
                <Typography
                  sx={{
                    display: { xs: "none", sm: "block", md: "block" },
                    ml: { xs: "0", sm: ".5rem", md: ".5rem" },
                  }}
                >
                  List Your Property
                </Typography>
              </Box>
            </Buttons>
            {!localStorage.getItem("token") ? (
              <Button
                onClick={() => navigate("/registration")}
                startIcon={
                  <Suspense>
                    <LoginIcon />
                  </Suspense>
                }
                sx={{
                  backgroundColor: "#232674",
                  borderRadius: "5px",
                  fontWeight: "400",
                  color: "White",
                  textTransform: "capitalize",
                  px: ".8rem",
                  ml: { xs: ".5rem", sm: "1rem" },
                  "&:hover": {
                    backgroundColor: "#232674",
                    color: "White",
                  },
                  "& .MuiButton-startIcon": {
                    display: { xs: "none", sm: "flex" },
                  },
                }}
              >
                Login
                <Typography
                  sx={{
                    display: { xs: "none", sm: "block", md: "block" },
                  }}
                >
                  /Register
                </Typography>
              </Button>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: { xs: 0, sm: 2, md: 2 },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "1rem", sm: "1.2rem" },
                      fontWeight: 500,
                      display: { xs: "none", sm: "block" },
                    }}
                  >
                    {(userId && token && userData?.first_name) || name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title="Account">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: { xs: 0, sm: 2, md: 2 } }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Avatar
                        sx={{ width: "2.6rem", height: "2.6rem" }}
                        src="https://img.freepik.com/premium-photo/cartoonish-3d-animation-boy-glasses-with-blue-hoodie-orange-shirt_899449-25777.jpg"
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            )}

            <Buttons onClick={handleHelpSection} bgColor={"#d40000"} sx={{}}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    display: { xs: "none", sm: "block", md: "block" },
                  }}
                >
                  Help&nbsp;
                </Typography>
                ?
              </Box>
            </Buttons>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/profileDashboard", { state: { favourite: 1 } });
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Suspense>
                    <FavoriteBorderIcon fontSize="small" />
                  </Suspense>
                </ListItemIcon>
                My Favourite
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/profileDashboard", { state: { booking: 2 } });
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Suspense>
                    <ArticleOutlinedIcon fontSize="small" />
                  </Suspense>
                </ListItemIcon>
                My Bookings
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate("/profileDashboard");
                }}
              >
                <ListItemIcon>
                  <Suspense>
                    <PersonOutlinedIcon fontSize="small" />
                  </Suspense>
                </ListItemIcon>
                My Account
              </MenuItem>
              <MenuItem
                onClick={() => {
                  localStorage.clear();
                  toast.success("Logout Successfully");
                  navigate("/login");
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Suspense>
                    <Logout fontSize="small" />
                  </Suspense>
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Navbar;
